<ng-container *ngIf="platformIsBrowser">
  <div class="header">
    <a [attr.href]="review.customerLink" [ngClass]="{'nolink': !review.customerLink}" target="_blank"
       class="avatar" *ngIf="customerDisplayStyle == 'avatar'">
      <mat-icon svgIcon="star-face"></mat-icon>
    </a>
    <div class="heading">
      <h3>
        {{review.title}}
      </h3>
      <div class="title">
        <a [attr.href]="review.customerLink"  target="_blank" [ngClass]="{'nolink': !review.customerLink}">{{review.customerName}}</a>
      </div>
      <div class="stars" *ngIf="!review.isAggregate">
        <span>
          {{review.createdAt | date:'M/d/yy @ h:mma' }}
        </span>
        <mat-icon svgIcon="star" *ngFor="let x of starsAsArray"></mat-icon>
      </div>
    </div>
  </div>
  <a class="content" href="{{review.link}}" target="_blank">
    {{ review.comments}}
  </a>
  <div class="customer-name" *ngIf="!review.isAggregate && customerDisplayStyle == 'text'">
    <a href="{{review.link}}" target="_blank">{{review.customerName}}</a>
    on
    <a href="{{review.link}}" target="_blank">TrustPilot.com</a>
  </div>

  <div class="overview" *ngIf="review.isAggregate">
    <strong>{{review.rating}}/{{review.maxRating}}</strong>&nbsp;<a href="{{review.link}}" target="_blank">Rating</a><br />
      <div class="stars">
        <mat-icon svgIcon="star" *ngFor="let x of [1,2,3,4,5]"></mat-icon>
      </div>
    <strong>{{review.totalReviews}}</strong>&nbsp;<a href="{{review.link}}" target="_blank">Reviews</a>
  </div>

</ng-container>
