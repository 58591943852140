<div class="image" *ngIf="activeView !== 'hanger'"
     [ngStyle]="{'background-image': 'url(/assets/images/products/frames/' + moulding.code + '/' + activeView + '-screen.jpg)'}">
  <!--
     [ngStyle.gt-md]="{'background-image': 'url(/assets/images/products/frames/' + moulding.code + '/' + activeView + '-large.jpg)'}">
  -->
</div>
<div class="image" *ngIf="activeView === 'hanger'"
     [ngStyle]="{'background-image': 'url(/assets/images/products/hangers/' + hangerType + '-screen.jpg)'}">
  <!--
   [ngStyle.gt-md]="{'background-image': 'url(/assets/images/products/hangers/' + hangerType + '-large.jpg)'}">
   -->
</div>

<div class="thumbs">
<ng-container *ngFor="let view of ['profile','corner','front','back','hanger','spec']">
  <div class="thumb" *ngIf="view !== 'hanger'"
       (click)="setActive(view)" [ngClass]="{active: view == activeView}"
       [style.background-image]="'url(/assets/images/products/frames/' + moulding.code + '/' + view + '-thumb.jpg)'">
  </div>
  <div class="thumb" *ngIf="view === 'hanger'"
       (click)="setActive(view)" [ngClass]="{active: view == activeView}"
       [style.background-image]="'url(/assets/images/products/hangers/' + hangerType + '-thumb.jpg)'">
  </div>
</ng-container>

</div>

<div class="description" [innerHTML]="moulding.description">
</div>
