import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Project} from '../../models/project';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../../../builder/upload-dialog/upload-dialog.component';
import {UploadService} from '../../services/upload.service';
import {Design} from '../../models/design';
import {Subscription} from 'rxjs';
import {WaitModalComponent} from '../../../wait-modal/wait-modal.component';

@Component({
  selector: 'mbnm-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  host: {'[class.disabled]': 'disabled'}
})
export class UploaderComponent implements OnInit {

  constructor(private dialog: MatDialog, private uploadService: UploadService) { }

  @Input()
  disclaimerAccepted: boolean = false;

  @Output()
  filesSelected: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  uploadComplete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  uploadFailed: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  acceptedFileTypes: string = 'image/*';

  @Input()
  uploadPath = '';

  @Input()
  disabled: boolean = false;

  @Input()
  autoUpload: boolean = true;

  @Input()
  allowMultiple: boolean = false;

  ngOnInit() {
  }

  private fileList: FileList;
  change($event) {
    this.fileList = $event.target.files;
    this.filesSelected.emit(this.fileList);

    if (this.autoUpload) {
      this.startUpload();
      $event.target.value = '';
    }
  }

  private _subscription: Subscription;
  startUpload() {
    const dialogRef = this.dialog.open(WaitModalComponent, {});
    const instance = dialogRef.componentInstance;
    dialogRef.disableClose = true;
    instance.state = 'PROCESSING';
    instance.titles = {
      PROCESSING: 'Uploading'
    };
    instance.text = {
      PROCESSING: ''
    };

    this._subscription = this.uploadService.upload(this.fileList, this.uploadPath)
      .subscribe((result: any) => {
        if (result) {
          if (typeof result.progress === 'number') {
            this._uploadPercentage = result.progress;
            instance.percentComplete = result.progress;
          } else {
            this._uploadPercentage = 0;
            this._subscription.unsubscribe();
            this.uploadComplete.emit(result);
            dialogRef.close();
          }
        }
      }, (result: any) => {
        this._uploadPercentage = 0;
        this._subscription.unsubscribe();
        this.uploadFailed.emit(result.error);
        dialogRef.close();
      });
  }

  @ViewChild('fileInput') fileInput: ElementRef;
  clearUploads() {
    this.fileInput.nativeElement.value = '';
  }

  cancelIfNecessary($event) {
    if (this.uploadPercentage > 0 || !this.disclaimerAccepted) {
      $event.preventDefault();
    }

    if (!this.disclaimerAccepted) {
      const dialogRef = this.dialog.open(UploadDialogComponent, {});
      const instance = dialogRef.componentInstance;
      instance.data = {
        closeMethod : 'x'
      }
      instance.acceptedFileTypes = this.acceptedFileTypes;
      instance.allowMultiple = this.allowMultiple;
      instance.uploadPath = this.uploadPath;
      instance.uploadComplete.subscribe( (result) => {
        this.disclaimerAccepted = true;
        this.uploadComplete.emit(result);
      });
      instance.uploadFailed.subscribe( (result) => {
        this.disclaimerAccepted = true;
        this.uploadFailed.emit(result);
      });
    }
  }

  private _uploadPercentage:number;
  get uploadPercentage():number {
    if (!isNaN(this._uploadPercentage)) {
      return this._uploadPercentage;
    }

    return 0;
  }
}
