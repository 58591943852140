<ng-container *ngIf="valuesToShow.length > 0">
  <div *ngIf="columnTitle" class="title">{{ columnTitle }}</div>
  <div class="description" [ngClass]="{'is-column': columnTitle}">
    <mat-icon *ngIf="!hideBullet && !columnTitle" svgIcon="checkbox-marked-circle"></mat-icon>
    <span>
      <ng-content select="[prefix]"></ng-content>
      <ng-container *ngFor="let which of valuesToShow">
        <span [ngClass]="{'old-value': which === 'old', new: which === 'current' && isNew}">
          <ng-container *ngFor="let prop of properties">
            <span class="format-parts" *ngIf="value(prop, which)">
              <span *ngIf="prop.valueFilter == 'size'" [innerHtml]="applyFormat(prop.formatToken, value(prop, which) | size:'multichar' | inches)"></span>
              <span class="currency" *ngIf="prop.valueFilter == 'currency'" [innerHtml]="applyFormat(prop.formatToken, value(prop, which) | currency2 )"></span>
              <span *ngIf="!prop.valueFilter" [innerHtml]="applyFormat(prop.formatToken, value(prop, which))"></span>
            </span>
          </ng-container>
        </span>
      </ng-container>
    </span>
  </div>
  <ng-content select="[after]"></ng-content>
</ng-container>
