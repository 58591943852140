import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ReviewsService {
  reviewBaseUrl = environment.baseUrls().api + 'reviews/';
  constructor(private httpClient: HttpClient) { }

  getAggregateInfo(): Observable<any> {
    return this.httpClient
      .get(this.reviewBaseUrl + 'aggregate')
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getCustomerReviews(limit: number = 10, tag: string = null): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.reviewBaseUrl + '?perPage=' + limit;
    if (tag) {
      url += ('&tag=' + tag);
    }

    return this.httpClient
      .get(url)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
