import {Component, HostBinding, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {DatePipe, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'mbnm-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [DatePipe]
})
export class ReviewComponent implements OnInit {

  @HostBinding('class.aggregate')
  get aggregate() {
    return this.review ? this.review.isAggregate : false;
  }

  @Input()
  review: any;

  @Input()
  customerDisplayStyle = 'avatar';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  get starsAsArray(): Array<number> {
    if (this.review) {
      return Array.apply(null, Array(this.review.rating)).map(function (x, i) { return i + 1; });
    }
    return [];
  }

  ngOnInit() {
  }

  public platformIsBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
