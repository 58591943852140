import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'mbnm-wait-modal',
  templateUrl: './wait-modal.component.html',
  styleUrls: ['./wait-modal.component.scss']
})
export class WaitModalComponent implements OnInit {

  constructor(private router:Router, private dialogRef:MatDialogRef<WaitModalComponent>) {

  }

  public state:string;
  public titles:any = {PROCESSING: 'Processing', DONE: 'Done!', ERROR: 'Error!'};
  public text:any = {PROCESSING: 'Process request', DONE: 'Done with request!', ERROR: 'Error with request!'};
  public yesButton:any;
  public noButton:any;
  public percentComplete: number;

  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.dialogRef.close();
        }
      });
  }

}
