import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class SessionService {

  public static currentUserChanged: ReplaySubject<User> = new ReplaySubject(1);
  public static abTestsChanged: ReplaySubject<any> = new ReplaySubject(1)

  private static abTests: any = {
    attemptedOptimizeRegistrations: {}
  };

  protected baseUrl = environment.baseUrls().api + 'session/';

  constructor(private httpClient: HttpClient,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  delete(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient
        .delete(this.baseUrl)
        .pipe(
          catchError( () => {
            return of(null);
          }),
          map((user: Object) => {
            this.updateCurrentUser(user as User);
            return user as User;
          })
        );
    } else {
      return of(null);
    }
  }

  get(): Observable<User> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient
        .get(this.baseUrl)
        .pipe(
          catchError( () => {
            return of(null);
          }),
          map((user: Object) => {
            this.updateCurrentUser(user as User);
            return user as User;
          })
        );
    } else {
      return of(null);
    }
  }

  post(): Observable<User> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient
        .post(this.baseUrl, null)
        .pipe(
          catchError(() => {
            return of(null);
          }),
          map((user: Object) => {
            this.updateCurrentUser(user as User);
            return user as User;
          })
        );
    } else {
      return of(null);
    }
  }

  signIn(email: string, password: string): Observable<User> {
    return this.httpClient
      .post(this.baseUrl + 'signin', {
        email: email,
        password: password
      })
      .pipe(
        map((user: Object) => {
          this.updateCurrentUser(user as User);
          return user as User;
        })
      );
  }

  signOutAndGetAnonymousUser(): void {
    // remove current token
    this.delete().subscribe(); // re-get the current user
  }

  updateCurrentUser(user: User): void {
    SessionService.currentUserChanged.next(user);
  }

  public registerExperimentIfNecessary(variant, name): void {
    if (!SessionService.abTests.attemptedOptimizeRegistrations[name + '_' + variant]) {
      SessionService.abTests.attemptedOptimizeRegistrations[name + '_' + variant] = true;
      this.httpClient
        .post(this.baseUrl + 'experiment', {
          name: name,
          variant: variant
        })
        .subscribe((experiment: any) => {
          if (experiment && experiment.name && experiment.variant) {
            if (!SessionService.abTests[experiment.name]) {
              SessionService.abTests[experiment.name] = {};
            }
            if (!SessionService.abTests[experiment.name][experiment.variant]) {
              SessionService.abTests[experiment.name][experiment.variant] = {};
            }
            SessionService.abTestsChanged.next(SessionService.abTests);
          }
        });
    }
  }
}
