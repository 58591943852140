export const environment: any = {
  production: true,
  protocol: 'https:',
  domain: 'www.matboardandmore.com',
  baseUrls: function() {
    const hasWindow = typeof window !== 'undefined';
    const domain = hasWindow ? window.location.hostname : this.domain;
    const postfix = domain.startsWith('live') ? '-live' : '';
    const protocol = hasWindow ? window.location.protocol : this.protocol;
    const apiUrl = protocol + '//api' + postfix + '.matboardandmore.com/';
    const site = protocol + '//' + (postfix === '' ? 'www' : postfix.replace('-', '')) + '.matboardandmore.com';
    return {
      site: site,
      serverSideSite: site,
      cms: protocol + '//cms' + postfix + '.matboardandmore.com/',
      api: apiUrl,
      preview: apiUrl + 'preview?',
      previewShare: site + 'share/'
    };
  },
  sizeLimits: {
    opening: {min: 1, max: 38},
    outer: {min: 4, max: 40, secondaryMax: 32}
  },
  easelLimits: {
    code: 'EASEL',
    availableSizes: ['5x7','7x5','8x10','10x8','11x14','14x11'],
    mouldingWidth: 1.25,
    fallbackBackingCode: 'FOAM'
  },
  glazingLimits: {
    shorterDim: 16,
    longerDim: 20,
    thickness: 0.05
  },
  minMargins: {
    ui: 36,
    minUnder: 0.5,
    minOver: 1
  },
  hangerConditions: {
    sawtoothSizeLimit: 20,
    metalCategoryName: 'Metal'
  },
  vGroove: {
    disallowedMats: '9632,SRM989,SRM988,9877,6989',
    offset: {
      openingEdgeMinOffset: 0.5,
      openingEdgeMaxOffset: 1.0,
      outerEdgeMinOffset: 0.5,
      vGrooveOffsetIncrement: 0.25
    }
  },
  disablePreventNavigation: false,
  trustPilotUrl: 'https://api.trustpilot.com/v1/business-units/554bccfb0000ff00057f45b9',
  trustPilotApiKey: 'bOmkVrB1sWa02Iw2XzAcrWaeIquGvKB3',
  minBackingThicknessForFrames: 0.125,
  qtySuggestions: [5, 10, 25, 50, 100, 500, 1000],
  activePaymentProcessor: 'SQUARE',
  acceptJS: {
    scriptUrl: 'https://js.authorize.net/v1/Accept.js',
    publicKey: '7k753X7a9jfCg8Lt57p46Bh8KMuvznVnJ7N2T2PLHKajvHGFdkpVZF9y9ekk932q',
    loginId: '6X9vfmX7yjzL'
  },
  squareSDK: {
    scriptUrl: 'https://web.squarecdn.com/v1/square.js',
    locationId: 'LRMF2JF4Z79KY',
    applicationId: 'sq0idp-WZemmFslc1Qlo8wa7PMwRQ'
  },
  complicatedCornerMinOpeningSize: {
    width: 3,
    height: 3
  },
  defaultParts: {
    topMat: 'SRM3297',
    bottomMat: 'SRM921A',
    moulding: 'FR26099'
  },
  wc: {
    defaultProducts: {
      mat: '4554',
      frame: '4556',
      sample: null
    }
  },
  googlePlacesApiKey: 'AIzaSyCH6CKFqxUHKh_ou6v1dAXhzO5XneURR24',
  bugSnagApiKey: 'adc9a86830c05e5c9234e5bb71188aba'
};
