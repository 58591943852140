import {Directive, Input, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[mbnmAddClass]'
})
export class AddClassDirective {

  @Input('mbnmAddClass') className:string;
  @Input('mbnmAddClassSelector') selector:string;

  renderer:Renderer2;
  nativeElement:any;

  constructor(renderer:Renderer2, rootElement: ElementRef) {
    this.renderer = renderer;
    this.nativeElement = rootElement.nativeElement;
  }

  ngOnInit() {
    let els = this.nativeElement.querySelectorAll(this.selector);
    for (var i:number=0; i < els.length; i++) {
      var el = els[i];
      this.renderer.addClass(el, this.className);
    }
  }
}
