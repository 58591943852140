import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {ReviewsService} from '../../services/reviews.service';

@Component({
  selector: 'mbnm-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  constructor(private reviewsService: ReviewsService) { }

  @Input() tag: string;
  @Input() reviewCount = 5;
  @Input() title = 'Customer Reviews';
  @Input() cycle = false;
  @Input() showOverview = false;
  @Input() customerDisplayStyle = 'avatar';

  reviews: Array<any>;

  @ViewChild('reviewTab') reviewTab: MatTabGroup;

  private interval: any;

  ngOnInit() {
    this.reviewsService
      .getCustomerReviews(this.reviewCount, this.tag)
      .subscribe( (reviews) => {
        this.reviews = reviews;
        if (this.showOverview) {
          this.reviewsService
            .getAggregateInfo()
            .subscribe( (result: any) => {
              const tpReviewSummary = result.find(r => r.provider === 'TrustPilot');
              // will need this later --> let rrReviewSummary = result.find(r => r.provider === 'ResellerRatings');

              this.reviews.unshift({
                maxRating: tpReviewSummary.maxRating,
                title: 'Order Today!',
                comments: 'Join the thousands of satisfied customers who trust us to give them the very best in service & quality.',
                link: 'https://www.trustpilot.com/review/matboardandmore.com',
                rating: tpReviewSummary.rating,
                totalReviews: tpReviewSummary.reviewCount,
                isAggregate: true
              });

              this.resetTimer();
            });
        }
      });
  }

  private resetTimer() {
    clearInterval(this.interval);
    this.interval = setInterval( () => {
      this.changeIndex(1, false);
    }, 7000);
  }

  changeIndex(delta: number, resetTimer: boolean = true) {
    if (this.reviewTab.selectedIndex + delta >= this.reviews.length) {
      this.reviewTab.selectedIndex = 0;
    } else if (this.reviewTab.selectedIndex + delta < 0) {
      this.reviewTab.selectedIndex = this.reviews.length - 1;
    } else {
      this.reviewTab.selectedIndex = this.reviewTab.selectedIndex + delta;
    }
    if (resetTimer) this.resetTimer();
  }
}
