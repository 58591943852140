import { Injectable } from '@angular/core';
import {map, mergeMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionService} from './session.service';
import {CookieService} from '../../cookie.service';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient, private sessionService: SessionService, protected cookieService: CookieService) {
  }

  register(newUserObj): Observable<User> {
    return this.httpClient
      .post(environment.baseUrls().api + 'user', newUserObj)
      .pipe(
        map((res:any) => {
          const date = new Date();
          date.setDate(date.getDate() + 60);
          this.cookieService.put('CurrentToken', res.currentToken, {
            expires: date,
            path: '/'
          });
          const user: User = new User(res);
          this.sessionService.updateCurrentUser(user);
          return user;
        })
      );
  }

  update(userObj): Observable<User> {
    return this.httpClient
      .patch(environment.baseUrls().api + 'user', userObj)
      .pipe(
        map((res: any) => {
          const user: User = new User(res);
          this.sessionService.updateCurrentUser(user);
          return user;
        })
      );
  }

  signUpForMailingList(userObj): Observable<User> {
    return this.httpClient
      .post(environment.baseUrls().api + 'user', userObj)
      .pipe(
        map((res: any) => {
          const user: User = new User(res);
          this.sessionService.updateCurrentUser(user);
          return user;
        })
      );
  }

  resetPassword(newPassword: string, forgotPasswordSecret: string): Observable<User> {
    const formData = new FormData();
    formData.append('newPassword', newPassword);
    formData.append('forgotPasswordSecret', forgotPasswordSecret);

    return this.httpClient
      .post(environment.baseUrls().api + 'user/password', formData)
      .pipe(
        map((res: any) => {
          const u: User = new User(res);
          this.sessionService.updateCurrentUser(u);
          return u;
        })
      );
  }

  updatePassword(email: string, oldPassword: string, newPassword: string): Observable<User> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('oldPassword', oldPassword);
    formData.append('newPassword', newPassword);

    return this.httpClient
      .patch(environment.baseUrls().api + 'user/password', formData)
      .pipe(
        map((res: any) => {
          const u: User = new User(res);
          this.sessionService.updateCurrentUser(u);
          return u;
        })
      );
  }

  forgotPassword(email: string): Observable<any> {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/json; charset=utf-8',
    });
    return this.httpClient
      .post(environment.baseUrls().api + 'user/forgotpassword', JSON.stringify(email), { headers} );
  }

  getCoupons(): Observable<any> {
    return this.httpClient
      .get(environment.baseUrls().api + 'user/coupons/');
  }
}
