import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CartService} from './cart.service';

@Injectable()
export class CouponService {

  constructor(
    private httpClient: HttpClient) {
  }


  applyToCart(code): Observable<any> {
    return this.httpClient
      .put(environment.baseUrls().api + 'coupons/cart/' + code, null)
      .pipe(
        map(shoppingCart => {
          CartService.currentCartChanged.next(shoppingCart);
          return shoppingCart;
        })
      );
  }

  removeFromCart(): Observable<any> {
    return this.httpClient
      .delete(environment.baseUrls().api + 'coupons/cart')
      .pipe(
        map(shoppingCart => {
          CartService.currentCartChanged.next(shoppingCart);
          return shoppingCart;
        })
      );
  }

  getLoyaltyCoupons(): Observable<any> {
    return this.httpClient
      .get(environment.baseUrls().api + 'coupons/loyalty');
  }

  getUserCoupons(): Observable<any> {
    return this.httpClient
      .get(environment.baseUrls().api + 'coupons/user');
  }

  verifyLoyaltyCouponEligibility(): Observable<any> {
    return this.httpClient
      .get(environment.baseUrls().api + 'coupons/loyalty/eligibility', {responseType: 'text'});
  }

  generateLoyaltyCoupon(): Observable<any> {
    return this.httpClient
      .post(environment.baseUrls().api + 'coupons/loyalty/generate', null);
  }

  claimLoyaltyCoupon(claimCode: string, verificationValue: string): Observable<any> {
    return this.httpClient
      .post(environment.baseUrls().api + 'coupons/loyalty/claim', {
        claimCode: claimCode,
        verificationValue: verificationValue
      });
  }
}
