<mbnm-navigation-tile *ngIf="heroTile" [tile]="heroTile" mode="hero"></mbnm-navigation-tile>

<div class="fx-layout-row fx-layout-wrap
            fx-align--x-space-between fx-align--center-x
            fx-gap--32px">
  <ng-container *ngFor="let tiles of distributedTiles">
    <mbnm-navigation-tile class="fx-flex--30--gt-sm fx-flex--45--sm fx-flex--100--xs fx-gap--16px"
                          *ngFor="let tile of tiles" [tile]="tile" [mode]="mode" [linkStyle]="linkStyle" />
  </ng-container>

</div>
