<h2 *ngIf="title">{{title}}</h2>
<mat-spinner color="secondary" *ngIf="!reviews"></mat-spinner>
<ng-container *ngIf="reviews && !cycle" >
  <mbnm-review *ngFor="let review of reviews" [review]="review">

  </mbnm-review>
</ng-container>
<ng-container *ngIf="reviews && cycle" >
  <button mat-icon-button class="left" (click)="changeIndex(-1)">
    <mat-icon svgIcon="chevron-left"></mat-icon>
  </button>
  <mat-tab-group class="reviews" #reviewTab headerPosition="below" dynamicHeight="true" [@.disabled]="true"
                 (swipeleft)="changeIndex(1)" (swiperight)="changeIndex(-1)">
    <mat-tab *ngFor="let review of reviews">
      <mbnm-review [review]="review" class="cycle" [customerDisplayStyle]="customerDisplayStyle">

      </mbnm-review>
    </mat-tab>
  </mat-tab-group>
  <button mat-icon-button class="right" (click)="changeIndex(1)">
    <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>
</ng-container>
<div *ngIf="reviews && reviews.length == 0">
  No Reviews Found!
</div>
